import React, { FC } from 'react'

export const GreenDotCheckMarkIcon: FC = () => (
  <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1">
    <title>Check</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-112.000000, -208.000000)">
        <g transform="translate(112.000000, 208.000000)">
          <path
            d="M42,21 C42,32.598 32.598,42 21,42 C9.402,42 0,32.598 0,21 C0,9.402 9.402,0 21,0 C32.598,0 42,9.402 42,21"
            fill="#73db2f"
          ></path>
          <path
            d="M17.4673,27.084 C18.4103,26.117 19.3343,25.172 20.2563,24.226 C23.9853,20.398 27.7153,16.571 31.4443,12.745 C32.0933,12.079 32.8683,12.102 33.3403,12.805 C33.5953,13.187 33.5903,13.589 33.3513,13.977 C33.2583,14.13 33.1343,14.267 33.0083,14.396 C28.1613,19.367 23.3123,24.337 18.4643,29.308 C17.9633,29.821 17.3953,29.883 16.8363,29.458 C14.2193,27.473 11.6073,25.482 8.9953,23.493 C8.5663,23.166 8.3713,22.745 8.5173,22.204 C8.6393,21.748 9.0093,21.44 9.5123,21.386 C9.8773,21.346 10.1723,21.515 10.4483,21.727 C12.0923,22.982 13.7363,24.238 15.3803,25.493 C16.0733,26.022 16.7673,26.551 17.4673,27.084"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
