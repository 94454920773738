import React, { Component } from 'react'

import styles from './ErrorBoundary.module.scss'
import { ExclamationIcon } from 'components/ExclamationIcon'
import { Page } from 'components/Page'
import { UnauthorizedHeader } from '../UnauthorizedHeader'
import { Link } from 'react-router-dom'
import { Row } from 'react-bootstrap'

export class ErrorBoundary extends Component<Record<string, unknown>, { hasError: boolean }> {
  state = { hasError: false }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Page renderHeader={() => <UnauthorizedHeader hideMenu />}>
          <div className={styles.ExclamationIconContainer}>
            <ExclamationIcon />
          </div>
          <p className={styles.ErrorText}>Oops! Something went wrong.</p>
          <Row className="justify-content-center mb-3">
            <Link to="/" onClick={() => this.setState({ hasError: false })}>
              Go Home
            </Link>
          </Row>
        </Page>
      )
    }

    return this.props.children
  }
}
