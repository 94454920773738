import React, { FC } from 'react'
import Spinner from 'react-bootstrap/Spinner'

import styles from './Loader.module.scss'

export const Loader: FC = () => (
  <div className="d-flex align-items-center justify-content-center flex-grow-1 w-100">
    <Spinner animation="border" className={styles.Loader} role="status" />
  </div>
)
