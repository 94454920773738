import React, { createContext } from 'react'

import { CurrentUser, SetErrorMessage, SetUser } from 'types'
import { SuspendedActions } from 'components/App/suspendedActions'

export interface ContextInterface {
  initializing: boolean
  notificationCount: number
  refreshNotificationCount: () => unknown
  setUser: SetUser
  suspendedRetriesRef: React.MutableRefObject<SuspendedActions>
  user?: CurrentUser
}

export type ErrorContext = { errorMessage: string | null; setErrorMessage: SetErrorMessage }

export type UnauthorizedErrorContext = {
  errorMessage: string | null
  setErrorMessage: (errorMessage: string | null) => void
}

export const AppContext = createContext<ContextInterface | undefined>(undefined)

export const ErrorMessageContext = createContext<ErrorContext | undefined>(undefined)

/**
 * Used to display the `<BarAlert />` within the `<UnauthorizedPage />`
 */
export const UnauthorizedErrorMessageContext = createContext<UnauthorizedErrorContext | undefined>(
  undefined,
)
