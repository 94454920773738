export const ENDPOINTS = {
  address: '/address',
  assets: '/asset',
  comments: '/comment',
  error: '/error',
  file: '/file',
  grid: '/grid',
  gridConfigs: '/grid_config',
  job: '/job',
  quote: '/quote',
  setting: '/setting',
  user: '/user',
}
