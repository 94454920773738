import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

import { Footer } from 'components/Footer'
import styles from './Page.module.scss'

export interface PageProps {
  description?: string
  fitOnScreen?: boolean
  hideFooter?: boolean
  renderHeader?: () => ReactNode
  title?: string
}

export const Page: FC<PageProps> = ({
  children,
  description = 'OnePak Partner Community',
  fitOnScreen = true,
  hideFooter = false,
  renderHeader,
  title = 'ReturnCenter Community',
}) => (
  <div className={classNames(styles.Page, { [styles.FitOnScreen]: fitOnScreen })}>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>

    {renderHeader ? renderHeader() : null}

    <div
      className={classNames(styles.Container, {
        [styles.ContainerWithHeader]: renderHeader !== undefined,
      })}
    >
      <Container fluid className={styles.Children}>
        {children}
      </Container>
    </div>

    {hideFooter || <Footer />}
  </div>
)
