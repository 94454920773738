import { BaseApiResult, Perspective, User } from 'types'
import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'

export type UserResponse = BaseApiResult & {
  brand_id: number
  cell_phone: string
  country: string
  culture_id: number
  email: string
  first_name: string
  last_name: string
  perspective_id: number
  phone: string
  send_notification_email: 0 | 1
}

type GetUser = (params?: { userId?: string }) => Promise<User>
export const getUser: GetUser = async (params = {}) => {
  const { userId } = params
  const { data: user } = await api.get<UserResponse>(ENDPOINTS.user, {
    params: {
      action: 'get',
      user_id: userId,
    },
  })

  return {
    brandId: user.brand_id,
    cellPhone: user.cell_phone,
    country: user.country,
    cultureId: user.culture_id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    perspectiveId: user.perspective_id as Perspective,
    phone: user.phone,
    sendNotificationEmail: Boolean(user.send_notification_email),
  }
}
