import { AxiosRequestConfig } from 'axios'

import {
  isArrayBuffer,
  isArrayBufferView,
  isBlob,
  isFile,
  isFormData,
  isURLSearchParams,
} from './utils'

export const attachToken = (config: AxiosRequestConfig, token: string): AxiosRequestConfig => {
  const method = config.method?.toLowerCase()
  if (method === 'get') {
    if (!(isURLSearchParams(config.params) || typeof config.params === 'string')) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      config.params = {
        ...config.params,
        token,
      }
    }
  } else {
    if (isFormData(config.data)) {
      config.data.append('token', token)
    } else if (typeof config.data === 'string') {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data = JSON.parse(config.data)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        config.data = {
          ...data,
          token,
        }
      } catch {}
    } else if (
      !(
        isArrayBuffer(config.data) ||
        isArrayBufferView(config.data) ||
        isBlob(config.data) ||
        isFile(config.data) ||
        isURLSearchParams(config.data)
      )
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      config.data = {
        ...config.data,
        token,
      }
    }
  }

  return config
}
