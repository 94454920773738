import React, { FC } from 'react'

export const ExclamationIcon: FC = () => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>!</title>
      <desc>Warning icon, an exclamation mark inside a yellow circle.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-429.000000, -292.000000)">
          <g transform="translate(429.000000, 292.000000)">
            <g>
              <path
                d="M30,15 C30,23.2842857 23.2842857,30 15,30 C6.71571429,30 0,23.2842857 0,15 C0,6.71571429 6.71571429,0 15,0 C23.2842857,0 30,6.71571429 30,15"
                fill="#F1C40F"
              ></path>
              <path
                d="M13.9854286,12.6765 C13.8954286,10.9650714 13.7968571,9.25435714 13.7225714,7.54292857 C13.7075714,7.19007143 13.7325714,6.82007143 13.8275714,6.48221429 C13.9604286,6.00935714 14.3282857,5.72435714 14.8118571,5.65292857 C15.1118571,5.60792857 15.429,5.61007143 15.7297143,5.65364286 C16.2768571,5.73221429 16.7125714,6.2365 16.764,6.84864286 C16.7968571,7.23578571 16.7825714,7.62864286 16.7618571,8.01792857 C16.6597143,10.0493571 16.5468571,12.0815 16.4397143,14.1136429 C16.364,15.5486429 16.2947143,16.9822143 16.209,18.4165 C16.1904286,18.7150714 16.1397143,19.0229286 15.9254286,19.2600714 C15.6668571,19.5472143 15.3325714,19.6143571 14.9761429,19.5143571 C14.5725714,19.4022143 14.3654286,19.0850714 14.3197143,18.6950714 C14.2554286,18.1515 14.2368571,17.6022143 14.2082857,17.0557857 C14.1311429,15.5965 14.0597143,14.1365 13.9854286,12.6765"
                fill="#FFFFFF"
              ></path>
              <path
                d="M16.8436429,23.2163571 C16.8536429,23.7599286 16.7400714,24.2535 16.2765,24.6049286 C15.7136429,25.0306429 14.7272143,25.0320714 14.1672143,24.6049286 C13.8000714,24.3242143 13.6536429,23.9227857 13.6143571,23.4892143 C13.5879286,23.1920714 13.6015,22.8827857 13.6529286,22.5892143 C13.7443571,22.0677857 14.0786429,21.7256429 14.5915,21.6263571 C14.9357857,21.5606429 15.3086429,21.5349286 15.6522143,21.5920714 C16.3693571,21.7113571 16.7993571,22.2449286 16.8429286,22.9706429 C16.8479286,23.0527857 16.8436429,23.1349286 16.8436429,23.2163571"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
