import React, { forwardRef, ComponentPropsWithoutRef } from 'react'
import { useField } from 'formik'
import { FormControl } from 'react-bootstrap'

type Props = ComponentPropsWithoutRef<FormControl> & {
  name: string
}

export const FormikInputFormControl = forwardRef<HTMLInputElement, Props>(
  ({ name, ...restProps }, ref) => {
    const [field] = useField(name)
    return <FormControl ref={ref} {...restProps} {...field} />
  },
)

FormikInputFormControl.displayName = 'FormikInputFormControl'
