import { api } from 'lib/api/base'
import { BaseApiResult, Company, Country, Culture, Perspective, Role, UserEditLists } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

type CompanyResponse = {
  brand_id: number
  brand_name: string
}
type CountryResponse = Country
type CultureResponse = Culture
type RoleResponse = {
  name: string
  perspective_id: Perspective
}

type UserEditListResponse = {
  company: CompanyResponse[]
  country: CountryResponse[]
  culture: CultureResponse[]
  role: RoleResponse[]
} & BaseApiResult

const mapCompany = ({ brand_id, brand_name }: CompanyResponse): Company => ({
  brandId: brand_id,
  brandName: brand_name,
})

const mapRole = ({ name, perspective_id }: RoleResponse): Role => ({
  name,
  perspectiveId: perspective_id,
})

type GetEditUserLists = () => Promise<UserEditLists>
export const getEditUserLists: GetEditUserLists = async () => {
  const {
    data: { company, country: countries, culture: cultures, role },
  } = await api.get<UserEditListResponse>(ENDPOINTS.user, {
    params: {
      action: 'get_edit_lists',
    },
  })

  return {
    companies: company.map(mapCompany),
    countries,
    cultures,
    roles: role.map(mapRole),
  }
}
