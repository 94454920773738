import { AxiosResponse } from 'axios'
import { E164Number } from 'libphonenumber-js'

import { api } from 'lib/api/base'
import { BaseApiResult, MenuItem, Perspective } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

type BaseUpdateUserParams = {
  cellPhone?: E164Number
  country: string
  cultureId: number
  email: string
  firstName: string
  lastName: string
  phone: E164Number
  sendNotificationEmail: boolean
}

type UpdateCurrentUserParams = BaseUpdateUserParams & {
  menuId: MenuItem
}

type UpdateOtherUserParams = BaseUpdateUserParams & {
  brandId: number
  perspectiveId: Perspective
}

type UpdateUserResponse = BaseApiResult & {
  locale: string
}

type UpdateUser = {
  (user: UpdateCurrentUserParams): Promise<{ locale: string }>
  (user: UpdateOtherUserParams, userId: string): Promise<{ locale: string }>
}

export const updateUser: UpdateUser = async (
  user: UpdateOtherUserParams | UpdateCurrentUserParams,
  userId?: string,
) => {
  const baseRequestArgs = {
    action: 'update',
    cell_phone: user.cellPhone,
    country: user.country,
    culture_id: user.cultureId,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    phone: user.phone,
    send_notification_email: Number(user.sendNotificationEmail),
  }

  let response: AxiosResponse<UpdateUserResponse>
  if (userId === undefined) {
    response = await api.post<UpdateUserResponse>(ENDPOINTS.user, {
      ...baseRequestArgs,
      menu_id: (user as UpdateCurrentUserParams).menuId,
    })
  } else {
    const updateOtherUserParams = user as UpdateOtherUserParams
    response = await api.post<UpdateUserResponse>(ENDPOINTS.user, {
      ...baseRequestArgs,
      brand_id: updateOtherUserParams.brandId,
      perspective_id: updateOtherUserParams.perspectiveId,
      user_id: userId,
    })
  }

  return { locale: response.data.locale }
}
