import React, { FC, useLayoutEffect, useRef } from 'react'

import { Action, SuspendedActions } from '../App/suspendedActions'
import { api } from '../../lib/api/base'
import { AppContext } from 'state/context'
import { attachToken } from '../App/attachToken'
import { CurrentUser, SetUser } from 'types'
import { isAddressVerificationError } from '../App/utils'
import { Loader } from '../Loader'
import { useErrorMessageContext } from '../../hooks/useErrorMessageContext'

export type PollingState = {
  notificationCount: number
  version: string
}

type ContextProviderProps = {
  children: React.ReactNode
  initializing: boolean
  notificationCount: number
  refreshNotificationCount: () => void
  setUser: SetUser
  user?: CurrentUser
}

export const activeSessionCookie = 'onepakWeb/activeSession'

export const ContextProvider: FC<ContextProviderProps> = ({
  children,
  initializing,
  notificationCount = 0,
  refreshNotificationCount,
  setUser,
  user,
}) => {
  const { setErrorMessage } = useErrorMessageContext()

  const suspendedRetriesRef = useRef(new SuspendedActions())

  useLayoutEffect(() => {
    if (user && !user.tokenExpired) {
      const { token } = user
      const reqInterceptorId = api.interceptors.request.use(config => {
        return attachToken(config, token)
      })
      const resInterceptorId = api.interceptors.response.use(
        response => response,
        error => {
          if (error.response?.status === 401) {
            setUser({ ...user, tokenExpired: true })
            return new Promise(resolve => {
              suspendedRetriesRef.current.add(resolve as Action)
            }).then(() => {
              return api.request(error.config)
            })
          } else if (error.response?.status < 500 && error.response.data.error) {
            if (!isAddressVerificationError(error)) {
              setErrorMessage(error.response.data.error)
            }
          } else {
            setErrorMessage('There was a problem fulfilling your request.')
          }

          return Promise.reject(error)
        },
      )
      return () => {
        api.interceptors.request.eject(reqInterceptorId)
        api.interceptors.response.eject(resInterceptorId)
      }
    }
  }, [setErrorMessage, setUser, user])

  return (
    <AppContext.Provider
      value={{
        initializing,
        notificationCount,
        refreshNotificationCount,
        setUser,
        suspendedRetriesRef,
        user,
      }}
    >
      {initializing ? (
        <div className="d-flex h-100">
          <Loader />
        </div>
      ) : (
        children
      )}
    </AppContext.Provider>
  )
}
