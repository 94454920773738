import { MenuConfig } from 'types'
import { menuItemRoutesLookup } from 'utils/menuItems'
import { report } from 'lib/api/error'
import { camelCaseKeys } from 'utils/camelCaseKeys'
import { MenuConfigResponse } from '../formatUserResponse'

const filterAndReportInvalidMenuItems = ({ menuId, description }: MenuConfig, token: string) => {
  if (menuItemRoutesLookup[menuId]) return true
  void report({
    errorMessage: `Unrecognized Menu Id: ${menuId}, ${description}`,
    identifier1: menuId,
    identifierType1: 'menu_id',
    token,
  })
  return false
}

const mapFilterAndReportSubMenuItems = (
  { menu, ...menuItem }: MenuConfig,
  token: string,
): MenuConfig => {
  if (!menu) return menuItem
  return {
    ...menuItem,
    menu: menu.filter(menuItem => filterAndReportInvalidMenuItems(menuItem, token)),
  }
}

type FilterMenuItems = (params: MenuConfigResponse[], token: string) => MenuConfig[]
export const filterMenuItems: FilterMenuItems = (menuConfigResponse, token) => {
  const responseWithPluralizedGrids = menuConfigResponse.map(({ grid, ...menu }) => {
    const menuWithPluralizedGrids = {
      ...menu,
      grids: grid,
    }
    if (menuWithPluralizedGrids.menu) {
      menuWithPluralizedGrids.menu = menuWithPluralizedGrids.menu.map(({ grid, ...menu }) => ({
        ...menu,
        grids: grid,
      }))
    }
    return menuWithPluralizedGrids
  })

  return camelCaseKeys<MenuConfigResponse[], MenuConfig[]>(responseWithPluralizedGrids, {
    deep: true,
  })
    .filter(menuItem => filterAndReportInvalidMenuItems(menuItem, token))
    .map(menuItem => mapFilterAndReportSubMenuItems(menuItem, token))
}
