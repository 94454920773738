import { AxiosPromise } from 'axios'

import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult, Comment, CommentType } from 'types'

type CommentResponse = {
  can_resolve?: string
  comment_date: string
  comment_id: string
  comment_text: string
  comment_type: CommentType
  customer_reference_num: string
  job_id: string
  status?: 'resolved' | 'unresolved'
  user_name: string
}

type GetCommentsParams = {
  commentType?: CommentType
  jobId?: string
  token?: string
}
type GetComments = (params?: GetCommentsParams) => Promise<Comment[]>
export const getComments: GetComments = async (params = {}) => {
  const { jobId, commentType, token } = params

  const {
    data: { comment: comments },
  } = await api.get<{ comment: CommentResponse[] }>(ENDPOINTS.comments, {
    params: {
      action: 'list',
      job_id: jobId,
      token,
      type: commentType,
    },
  })

  return comments.map((comment: CommentResponse) => ({
    canResolve: Boolean(comment.can_resolve),
    commentId: comment.comment_id,
    commentType: comment.comment_type,
    customerReferenceNum: comment.customer_reference_num,
    date: comment.comment_date,
    jobId: comment.job_id,
    status: comment.status,
    text: comment.comment_text,
    username: comment.user_name,
  }))
}

export type NotificationCountResponse = BaseApiResult & {
  count: number
  version: string
}
type NotificationCountState = {
  count: number
  version: string
}
type GetNotificationCount = () => Promise<NotificationCountState>
export const getNotificationCount: GetNotificationCount = async () => {
  const params = {
    action: 'count',
  }
  const {
    data: { count, version },
  } = await api.get<NotificationCountResponse>(ENDPOINTS.comments, { params })

  return { count, version }
}

type AddCommentParams = {
  commentText: string
  commentType: CommentType
  jobId: string
}
type AddComment = (params: AddCommentParams) => AxiosPromise<BaseApiResult>
export const addComment: AddComment = ({ commentText, commentType, jobId }) =>
  api.post(ENDPOINTS.comments, {
    action: 'add',
    comment_text: commentText,
    comment_type: commentType,
    job_id: jobId,
  })

type ResolveCommentParams = {
  commentId: string
  jobId: string
  newCommentText: string
}

type ResolveComment = (params: ResolveCommentParams) => AxiosPromise<BaseApiResult>
export const resolveComment: ResolveComment = ({ commentId, jobId, newCommentText }) =>
  api.post(ENDPOINTS.comments, {
    action: 'resolve',
    comment_id: commentId,
    job_id: jobId,
    new_comment_text: newCommentText,
  })
