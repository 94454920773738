import * as React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import * as yup from 'yup'

import * as routes from 'components/Router/Routes'
import styles from './LogInForm.module.scss'
import { TextField } from 'components/TextField'

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  username: yup.string().required(),
})

export type FormValues = {
  password: string
  username: string
}

export type Props = {
  onSubmit: (values: FormValues) => void
  showCreateNewAccount?: boolean
  showForgotPassword?: boolean
  showStartNewReturn?: boolean
}

export const LogInForm = ({
  onSubmit,
  showCreateNewAccount,
  showForgotPassword,
  showStartNewReturn,
}: Props): JSX.Element => {
  const usernameRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    usernameRef.current?.focus()
  }, [])

  return (
    <div>
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting }) => {
          if (isSubmitting) {
            usernameRef.current?.focus()
          }

          return (
            <Form className={styles.Form} onSubmit={handleSubmit}>
              <h2 className="text-center mb-5">Sign In</h2>
              <TextField
                className="mb-3"
                hideValidation
                label="Username"
                name="username"
                ref={usernameRef}
              />
              <TextField
                className="mb-3"
                hideValidation
                label="Password"
                name="password"
                type="password"
              />

              {showForgotPassword && (
                <Row className={styles.RightAlignedRow}>
                  <Link className={styles.ForgotPassword} to={routes.emailPasswordResetPath}>
                    Forgot password?
                  </Link>
                </Row>
              )}

              <Row className={styles.RightAlignedRow}>
                <Button className="w-75 mx-auto" disabled={isSubmitting} type="submit">
                  {isSubmitting ? (
                    <Spinner animation="border" style={{ height: '1rem', width: '1rem' }} />
                  ) : (
                    'Sign In'
                  )}
                </Button>
              </Row>

              {showStartNewReturn && (
                <Row className={styles.RightAlignedRow}>
                  <Button
                    className="w-75 mx-auto"
                    variant="dark"
                    onClick={() => {
                      window.open(process.env.REACT_APP_RC_URL)
                    }}
                  >
                    Start New Return
                  </Button>
                </Row>
              )}

              {showCreateNewAccount && (
                <p className="text-center">
                  No Account? <Link to={routes.createAccount}>Create One</Link>
                </p>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
