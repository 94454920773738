import React, { ComponentPropsWithoutRef, FocusEvent, forwardRef } from 'react'
import { FieldValidator, useField } from 'formik'
import { FormControl } from 'react-bootstrap'

type FormControlElement = HTMLInputElement | HTMLTextAreaElement

type Props = ComponentPropsWithoutRef<FormControl> & {
  name: string
  onBlur?: (value: string) => void
  validate: FieldValidator
}

export const FormikFieldAutoTrim = forwardRef<FormControlElement, Props>(
  ({ as, name, validate, ...restProps }, forwardedRef) => {
    const [field, , { setValue }] = useField({ name, validate })
    if (as && as !== 'textarea' && as !== 'input')
      throw new Error(
        'FormikFieldAutoTrim component is only for use with text inputs and textareas',
      )
    return (
      <FormControl
        {...field}
        {...restProps}
        as={as}
        ref={forwardedRef}
        onBlur={(event: FocusEvent<FormControlElement>) => {
          const trimmed =
            typeof event.target.value === 'string' ? event.target.value.trim() : event.target.value
          setValue(trimmed)

          if (restProps.onBlur) {
            restProps.onBlur(trimmed)
          }

          field.onBlur(event)
        }}
      />
    )
  },
)

FormikFieldAutoTrim.displayName = 'FormikFieldAutoTrim'
