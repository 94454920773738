import { Dispatch, ReactNode, SetStateAction } from 'react'
import { Column, SortingState, Table } from '@devexpress/dx-react-grid'

export type Address = {
  address1: string
  address2?: string
  address_id?: number
  city: string
  postal_code: string
  state_province: string
}

export interface AdvancedSearchFilter {
  columnId: number
  relationId?: Relation
  typeId: SearchType
  value: string | number
}

export type Asset = {
  asset_id: number
  icon: string
  name: string
}

export enum AssetDetailsType {
  expected = 'expected',
  pickedUp = 'picked_up',
}

export type AssetQuantity = {
  id: number
  quantity?: number
}

export type AssetType = {
  description: string
  serialNumberRequired: boolean
  typeId: number
}

export interface BaseApiResult {
  error?: string
  result: 'Success' | 'Failure'
}

export enum BoolSortDirection {
  asc = 0,
  desc = 1,
}

export type ChildrenProp = { children: ReactNode }

export type ColorKey = Array<{
  color: JobColor
  en: string
}>

export type ColumnExtension = Table.ColumnExtension & SortingState.ColumnExtension

export enum ColumnName {
  actualDeliveryDate = 'scanpak_extradata.actual_delivery',
  actualDeliveryDateHighlight = 'actual_delivery_date_highlight',
  actualServiceDate = 'scanpak_extradata.scan_pickup_date',
  actualServiceDateHighlight = 'actual_service_date_highlight',
  brandId = 'brand.brand_id',
  brandName = 'brand.brand_name',
  commentCount = 'comment_count',
  commentText = 'comment_text',
  comments = 'comments',
  customer = 'brand_clients.client_name',
  customerReferenceNum = 'jobs.customer_reference_num',
  customerReferenceNum2 = 'jobs.customer_reference_num2',
  deferEditModal = 'defer_edit_modal',
  deleteReportSchedule = 'delete_report_schedule',
  discrepancyEditModal = 'discrepancy_edit_modal',
  downloadDispatch = 'download_dispatch',
  dspEditModal = 'dsp_edit_modal',
  dspJob = 'dell_ars_extradata.dsp_job_id',
  dspSla = 'dell_ars_extradata.dsp_status_code',
  dspStage = 'dell_ars_extradata.dsp_status',
  dspStatusText = 'dell_ars_extradata.dsp_status_text',
  editReportSchedule = 'edit_report_schedule',
  edpCancelModal = 'edp_cancel_modal',
  edpEditModal = 'edp_edit_modal',
  edpLabelEditModal = 'edp_label_edit_modal',
  email = 'users.user_email',
  epAssetsUploadDate = 'dell_ars_extradata.Rcpt_Rpt_Sent',
  epAssetsUploadDateHighlight = 'ep_assets_upload_date_highlight',
  epAssetsUploadDueDate = 'dell_ars_extradata.Rcpt_Rpt_Due',
  epBrandName = 'ep_brand.brand_name',
  epCommentCount = 'ep_comment_count',
  epComments = 'ep_comments',
  epJob = 'dell_ars_extradata.ep_id',
  epSla = 'dell_ars_extradata.ep_status_code',
  epStage = 'dell_ars_extradata.ep_status',
  epStatusText = 'dell_ars_extradata.ep_status_text',
  jobContact1Name = 'job_contact1.name',
  jobContact1Phone = 'job_contact1.phone',
  jobContact2Name = 'job_contact2.name',
  jobContact2Phone = 'job_contact2.phone',
  jobId = 'jobs.job_id',
  jobLocationAddress = 'job_locations.address1',
  jobLocationCity = 'job_locations.city',
  jobLocationCountry = 'job_locations.country',
  jobLocationState = 'job_locations.state',
  jobLocationZip = 'jobs_locations.zip',
  jobStatus = 'job_step_status.display_sequence',
  labelSla = 'dell_ars_track.status_code',
  lpBrandName = 'lp_brand.brand_name',
  lpCommentCount = 'lp_comment_count',
  lpComments = 'lp_comments',
  lpEditModal = 'lp_edit_modal',
  lpJob = 'dell_ars_extradata.lp_job_id',
  lpSla = 'dell_ars_extradata.lp_status_code',
  lpStage = 'dell_ars_extradata.lp_stat_status',
  lpStatusText = 'dell_ars_extradata.lp_status_text',
  notificationCount = 'notification_count',
  onHoldEditModal = 'onhold_edit_modal',
  onHoldReason = 'dell_ars_extradata.hold_reason',
  outboundToLessor = 'dell_ars_extradata.outbound_to_lessor',
  outboundToLessorHighlight = 'outbound_to_lessor_highlight',
  partnerEditModal = 'partner_edit_modal',
  partnerLocationEditModal = 'partner_location_edit_modal',
  podReceiptDate = 'dell_ars_extradata.pod_last_date_rec',
  qtyPickedUp = 'qty_picked_up',
  qtyPickedUpHighlight = 'qty_picked_up_highlight',
  qtyReceived = 'qty_received',
  qtyReceivedHighlight = 'qty_received_highlight',
  qtySettled = 'qty_settled',
  qtySettledHighlight = 'qty_settled_highlight',
  receiptDateHighlight = 'pod_receipt_date_highlight',
  recoveryManager = 'job_remarketers_name',
  reportScheduleBody = 'report_schedule.body',
  reportScheduleEmailBcc = 'report_schedule.email_bcc',
  reportScheduleEmailCc = 'report_schedule.email_cc',
  reportScheduleEmailTo = 'report_schedule.email_to',
  reportScheduleSubject = 'report_schedule.subject',
  reportScheduleTypeId = 'report_schedule.type_id',
  reportSchedulesConfigId = 'report_schedules.config_id',
  reportSchedulesName = 'report_schedules.name',
  reportSchedulesScheduleId = 'report_schedules.schedule_id',
  reportSchedulesScheduleType = 'report_schedules.schedule_type',
  rescheduledServiceDate = 'dell_ars_extradata.rescheduled_pickup_date',
  rmEditModal = 'rm_edit_modal',
  ruleEditModal = 'rule_edit_modal',
  ruleSetEditModal = 'ruleset_edit_modal',
  scheduledServiceDate = 'scanpak_schedule.scheduled',
  scheduledServiceDateHighlight = 'scheduled_service_date_highlight',
  settlementDate = 'dell_ars_extradata.Steel_Rpt_Sent',
  settlementDateHighlight = 'settlement_date_highlight',
  settlementDueDate = 'dell_ars_extradata.Settl_Rpt_Due',
  sla = 'jobs.status_code',
  slaRuleDelete = 'rule_delete_modal',
  slaRuleId = 'sla_rule.rule_id',
  slaRuleSetDelete = 'ruleset_delete_modal',
  slaRuleSetId = 'sla_ruleset.set_id',
  slaRuleSetName = 'sla_ruleset.name',
  statusText = 'jobs.status_description',
  trackingId = 'job_tracking_info.tracking_id',
  trackingNumber = 'job_tracking_info.tracking_number',
  trackingNumberUrl = 'tracking_url',
  userDeleteModal = 'user_delete_modal',
  userEditModal = 'user_edit_modal',
  userId = 'users.user_id',
  viewLabel = 'view_label',
}

export type Comment = {
  canResolve: boolean
  commentId: string
  commentType: CommentType
  customerReferenceNum: string
  date: string
  jobId: string
  status?: 'resolved' | 'unresolved'
  text: string
  username: string
}

export enum CommentType {
  dspIssue = 'dsp_issue',
  dspIssueSolved = 'dsp_issue_solved',
  dspNote = 'dsp_note',
  epIssue = 'ep_issue',
  epIssueSolved = 'ep_issue_solved',
  epNote = 'ep_note',
  job = 'job',
  lpIssue = 'lp_issue',
  lpIssueSolved = 'lp_issue_solved',
  lpNote = 'lp_note',
  myIssue = 'my',
  rmIssue = 'rm_issue',
  rmIssueSolved = 'rm_issue_solved',
}

export type Company = {
  brandId: number
  brandName: string
}

export type Contact = {
  email?: string
  first_name: string
  last_name: string
  phone: string
}

export type Country = {
  code2: string
  country: string
  currency: string
}

export type Culture = {
  id: number
  name: string
}

export type CurrentUser = {
  brandId: number
  colorKey?: ColorKey
  cultureId: number
  defaultMenuId: MenuItem
  email: string
  expiredMessage: string
  firstName: string
  lastName: string
  locale: string
  loggedInAt: number
  menu: MenuConfig[]
  menuConfigIdCache: MenuConfigIdCache
  notificationIntervalMs: number
  paradigms: Paradigm[]
  parentBrandId: number
  passwordExpired: boolean
  passwordPolicy: string
  perspectiveId: Perspective
  referenceNumbers: OrderReferenceNumber[]
  token: string
  tokenExpired: boolean
  w9Url: string
}

export type Dimension = {
  height: number
  length: number
  width: number
}

export type DispatchRow = (number | string | null)[]

export interface DynamicObject {
  [key: string]: string | number | ReactNode
}

export type Grid = {
  configId: number
  current?: boolean
  name: string
}

export interface GridConfig {
  align: 'center' | 'left' | 'right'
  columnId: number
  dataOnly: boolean
  filterable: boolean
  heading: string
  headingAlignment: 'center' | 'left' | 'right'
  hidden: boolean
  highlightColumnId: number
  minimumWidth: string
  name: string
  sequence: number
  sortable: boolean
  tooltip: boolean
  typeId: number
  width: string
}

export enum JobColor {
  purple = 0,
  black = 1,
  green = 2,
  yellow = 3,
  red = 4,
  blue = 5,
  gray = 6,
}

export type Location = {
  address: Address
  company?: string
  contact: Contact
}

export type MenuConfig = {
  color: string
  configId?: number
  description: string
  grids?: Grid[]
  icon: string
  menu?: MenuConfig[]
  menuId: MenuItem
}

export type MenuConfigIdCache = { [key: number]: number }

export enum MenuItem {
  createNewOrder = 61,
  dashboard = 62,
  myAccount = 63,
  signOut = 64,
}

export interface MetaColumn extends Column {
  headingAlignment: 'left' | 'right' | 'center'
  highlightColumnName?: ColumnName
  tooltip: boolean
}

export type Order = {
  assets: AssetQuantity[]
  comment: string
  delivery_location?: Location
  emails?: string[]
  inboundPriceId?: string
  job_id?: string
  outboundPriceId?: string
  pallets: Pallet[]
  paradigm?: Paradigm
  payment?: PaymentType
  pickup_date_end?: Date
  pickup_date_start?: Date
  pickup_location?: Location
  products?: Array<ProductWithQuantity>
  reference_numbers: string[]
}

export type OrderReferenceNumber = {
  label: string
  mask: string
  required: boolean
}

export type OrderResponse = {
  assets_expected: OrderResponseAsset[]
  assets_picked_up: OrderResponseAsset[]
  billto_info: OrderResponseBillToInfo
  dates: OrderResponseDate[]
  delivery_location: OrderResponseLocation
  documents?: OrderResponseDocument[]
  job_type: string
  pallets_expected: OrderResponsePallet[]
  pallets_picked_up: OrderResponsePallet[]
  pickup_location: OrderResponseLocation
  products: OrderResponseProduct[]
  reference1: string
  reference2: string
  reference3: string
  status: string
  tracking_numbers: OrderResponseTrackingNumber[]
}

export type OrderResponseAddress = {
  address1: string
  address2: string
  city: string
  company: string
  country: string
  postal_code: string
  state_province: string
}

export type OrderResponseAsset = {
  asset_id: string
  description: string
  id: string
  quantity: string
  serial_number: string
}

export type OrderResponseBillToInfo = {
  address1: string
  address2: string
  city: string
  company: string
  email: string
  first_name: string
  last_name: string
  location_name: string
  phone: string
  postal_code: string
  state_province: string
}

export type OrderResponseContact = {
  email: string
  first_name: string
  last_name: string
  phone: string
}

export type OrderResponseDate = {
  date?: string
  date_range?: [string, string]
  label: string
  name: string
}

export type OrderResponseDocument = {
  create_date: string
  filename: string
  mime_type: string
  name: string
  title: string
  url: string
}

export type OrderResponseLocation = {
  address: OrderResponseAddress
  contacts?: OrderResponseContact[]
}

export type OrderResponsePallet = {
  asset_id: string
  description: string
  height: string
  length: string
  quantity: string
  weight: string
  width: string
}

export type OrderResponseProduct = {
  display_name: string
  icon: string
  product_id: string
  quantity: string
}

export type OrderResponseTrackingNumber = {
  create_date: string
  direction: string
  label_url: string
  shipper: string
  status: string
  tracking_number: string
  tracking_url: string
}

export type Pallet = {
  height?: string
  icon: string
  id: number
  length: string
  name: string
  quantity?: string
  weight?: string
  width: string
}

export type Paradigm = {
  asset_entry: ParadigmPermission
  assets: Asset[]
  description: string
  name: string
  pallet_entry: ParadigmPermission
  paradigm_id: number
  payment_terms: boolean
  product_entry: ParadigmPermission
  products: Array<Product>
}

export type ParadigmPermission = {
  allowed: boolean
  required: boolean
}

export enum PaymentType {
  CreditCard = 'CreditCard',
  Invoice = 'Invoice',
}

export enum Perspective {
  logisticsProvider = 4,
  environmentalPartner = 6,
  electronicsDispositionPartner = 7,
  dataSanitizationPartner = 32,
  dspEpAndLp = 33,
  dellManagement = 12,
  dellRecoveryManager = 17,
  dellSystemsAdministrator = 34,
  environmentalPartnerAndLogisticsProvider = 18,
  emeaEDP = 23,
  dellAdmin = 24,
  lessor = 25,
}

export type Product = {
  dimensions: Dimension
  display_name: string
  help_description: string
  help_icon: string
  icon: string
  product_id: number
  type: 'waybill' | 'box'
}

export type ProductWithQuantity = Product & { quantity: number }

export type Price = {
  amount: number
  description: string
  price_id: string
}

export type Quote = {
  price: Price[]
  product_price?: Price[]
  quote_id: string
  quote_number: string
}

export enum Relation {
  and = 1,
  or = 2,
}

export type Role = {
  name: string
  perspectiveId: Perspective
}

export type SearchAction = {
  buttonText: string
  configId: number
  filters: AdvancedSearchFilter[]
}

export type SearchResult = {
  actions: SearchAction[]
  company: string
  customerReferenceNum: string
  customerReferenceNum2: string
  jobId: string
  location: string
  serviceType: string
}

export enum SearchType {
  contains = 8,
  equals = 1,
  greaterThanEqualTo = 4,
  isNotEqualTo = 2,
  isOneOf = 7,
  lessThanEqualTo = 3,
  endsWith = 6,
  beginsWith = 5,
  doesNotContain = 9,
}

export type SelectOption = {
  label: string
  value: number | string
}

export type SetErrorMessage = (
  errorMessage: string | null,
  options?: {
    identifier1: number | string
    identifier2?: number | string
    identifierType1: string
    identifierType2?: string
  },
) => void

export type SetUser = Dispatch<SetStateAction<CurrentUser | undefined>>

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type User = {
  brandId: number
  cellPhone: string
  country: string
  cultureId: number
  email: string
  firstName: string
  lastName: string
  perspectiveId: Perspective
  phone: string
  sendNotificationEmail: boolean
}

export type UserEditLists = {
  companies: Company[]
  countries: Country[]
  cultures: Culture[]
  roles: Role[]
}

export type UsState = {
  name: string
  state: string
}
