export const alertsPath = '/alerts'
export const createNewOrder = '/create_new_order'
export const createAccount = '/create_account'
export const dashboardPath = '/dashboard'
export const dispatchSearchPath = '/dispatch_search'
export const emailPasswordResetPath = '/email_password_reset'
export const loginPath = '/login'
export const myAccountPath = '/account'
export const orderDetailsPath = (id?: string): string =>
  `/order_details/${id ? encodeURIComponent(id) : ':jobId'}`
export const resetPasswordSuccessPath = '/reset_password_success'
export const rootPath = '/'
