import { alertsPath, createNewOrder, dashboardPath, myAccountPath } from 'components/Router/Routes'
import { MenuItem } from 'types'

export const menuItemRoutesLookup = {
  [MenuItem.createNewOrder]: createNewOrder,
  [MenuItem.dashboard]: dashboardPath,
  [MenuItem.myAccount]: myAccountPath,
  [MenuItem.signOut]: 'SIGN OUT',
}

export const routesMenuItemLookup = Object.entries(menuItemRoutesLookup).reduce(
  (accumulator, [key, value]) => ({
    ...accumulator,
    [value]: Number(key) as MenuItem,
  }),
  {} as { [key: string]: MenuItem },
)

export const persistentRouteDescriptionMap: Record<string, string> = {
  [alertsPath]: 'Alerts',
}
