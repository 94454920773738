import * as React from 'react'
import BootstrapModal from 'react-bootstrap/Modal'
import classnames from 'classnames'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { Button } from 'components/Button'

import styles from './DialogBox.module.scss'

type Props = {
  buttonClassName?: string
  buttonText?: string
  children?: React.ReactNode
  dialogClassName?: string
  hide: () => void
  show?: boolean
  title?: React.ReactNode
}

export const DialogBox = ({
  buttonText = 'CANCEL',
  buttonClassName = '',
  children,
  dialogClassName = '',
  hide,
  show,
  title,
}: Props): JSX.Element => (
  <BootstrapModal
    centered
    className={styles.Modal}
    dialogClassName={classnames(styles.Dialog, dialogClassName)}
    onHide={hide}
    show={show}
  >
    <BootstrapModal.Header closeButton>
      <BootstrapModal.Title>{title}</BootstrapModal.Title>
    </BootstrapModal.Header>
    <BootstrapModal.Body>
      <Container fluid className={styles.Container}>
        {children}
        <Row className="justify-content-center mt-4">
          <Col xs={6}>
            <Button className={classnames(styles.Button, buttonClassName)} onClick={hide}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Container>
    </BootstrapModal.Body>
  </BootstrapModal>
)
