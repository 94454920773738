import React, { FC } from 'react'
import { Alert } from 'react-bootstrap'

import styles from './BarAlert.module.scss'

interface Props {
  onClose: () => void
}

export const BarAlert: FC<Props> = ({ children, onClose }) => (
  <div className={styles.AlertContainer}>
    <div className={styles.Alert}>
      <Alert dismissible onClose={onClose} variant="danger">
        {children}
      </Alert>
    </div>
  </div>
)
