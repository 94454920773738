import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'
import { FormValues } from 'forms/CreateAccountForm'

type AddAccountResponse = BaseApiResult & {
  token: string
}

export const addAccount = async (values: FormValues): Promise<string> => {
  const { data } = await api.post<AddAccountResponse>(ENDPOINTS.user, {
    action: 'add_account',
    bill_company: {
      address: {
        address1: values.billingInfo.address1,
        address2: values.billingInfo.address2,
        city: values.billingInfo.city,
        postal_code: values.billingInfo.postal_code,
        state_province: values.billingInfo.state_province,
      },
      company: values.companyInfo.name,
      contact: {
        email: values.email,
        first_name: values.billingInfo.firstName,
        last_name: values.billingInfo.lastName,
        phone: values.phone,
      },
    },
    company: {
      address: {
        address1: values.companyInfo.address1,
        address2: values.companyInfo.address2,
        city: values.companyInfo.city,
        postal_code: values.companyInfo.postal_code,
        state_province: values.companyInfo.state_province,
      },
      company: values.companyInfo.name,
      contact: {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone,
      },
    },
    password: values.password,
  })

  return data.token
}
