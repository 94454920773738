import React, { useEffect, useState } from 'react'
import { Form, Button, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { authorizePasswordChange, changePassword } from 'lib/api/user'
import { BootstrapFormikFeedback } from 'components/BootstrapFormikFeedback'
import { FormikInputFormControl } from 'components/FormikInputFormControl'
import { Loader } from 'components/Loader'
import { passwordValidator } from 'utils/passwordValidator'
import { UNAUTHORIZED_ROUTES } from 'Routes'
import { useUnauthorizedErrorMessageContext } from '../../hooks/useUnauthorizedErrorMessageContext'

import styles from './ResetPasswordPage.module.scss'

type FormData = {
  newPassword: string
  newPasswordConfirmation: string
}
type OnSubmit = (values: FormData) => void

export const ResetPasswordPage = (): JSX.Element => {
  const { uid } = useParams<{ uid: string }>()
  const [authorizing, setAuthorizing] = useState(true)
  const [invalidResponse, setInvalidResponse] = useState(false)
  const [token, setToken] = useState<string>('')
  const history = useHistory()

  const { setErrorMessage } = useUnauthorizedErrorMessageContext()

  const changeUserPassword = async (newPassword: string, token: string) => {
    try {
      await changePassword({
        newPassword,
        token,
      })
      history.push(UNAUTHORIZED_ROUTES.resetPasswordSuccess)
    } catch (e) {
      setErrorMessage(e.response.data.error)
    }
  }

  const onSubmit: OnSubmit = (values: FormData) => {
    void changeUserPassword(values.newPassword, token)
  }

  const ResetPasswordValidation = Yup.object().shape({
    newPassword: Yup.string().required('Please enter a password').test({
      name: 'passwordTest',
      exclusive: true,
      params: undefined,
      message: 'Password does not meet requirements',
      test: passwordValidator,
    }),
    newPasswordConfirmation: Yup.string()
      .required('Please confirm new password')
      .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
  })

  useEffect(() => {
    const authorizePasswordChangeToken = async (uid: string) => {
      try {
        const { error, result, token } = await authorizePasswordChange(uid)
        setErrorMessage(error ?? null)
        if (result === 'Success') {
          return token
        } else {
          setErrorMessage(error ?? null)
        }
      } catch (e) {
        setErrorMessage(e.response.data.error)
        setInvalidResponse(true)
      }
    }

    const authorize = async () => {
      try {
        const token = await authorizePasswordChangeToken(uid)
        setAuthorizing(false)
        token && setToken(token)
      } catch (e) {}
    }
    void authorize()
  }, [setErrorMessage, uid])

  return (
    <>
      {authorizing ? (
        <Loader />
      ) : (
        <>
          {!invalidResponse && (
            <div className={styles.FormWrapper}>
              <Formik
                initialValues={{ newPassword: '', newPasswordConfirmation: '' }}
                onSubmit={onSubmit}
                validationSchema={ResetPasswordValidation}
              >
                {({ handleSubmit }) => {
                  return (
                    <Form className={styles.Form} onSubmit={handleSubmit}>
                      <h2 className={styles.PromptHeading}>Reset Password</h2>
                      <p className={styles.PromptText}>
                        <strong>Note: your password is set up with the following rules:</strong>
                      </p>
                      <ul className={styles.PasswordCriteria}>
                        <li>Passwords expire after 90 days</li>
                        <li>Passwords must be at least 8 characters long</li>
                        <li>
                          Passwords must include 3 out of the following 4 options:
                          <ol>
                            <li>Uppercase Alpha Character</li>
                            <li>Lowercase Alpha Character</li>
                            <li>Numeric Character</li>
                            <li>&ldquo;Special&rdquo; Character(i.e., @,$,&amp;,*)</li>
                          </ol>
                        </li>
                      </ul>
                      <Form.Group controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <FormikInputFormControl
                          name="newPassword"
                          placeholder="Password"
                          type="password"
                        />
                        <BootstrapFormikFeedback name="newPassword" />
                      </Form.Group>

                      <Form.Group controlId="newPasswordConfirmation">
                        <Form.Label>Re-enter Password</Form.Label>
                        <FormikInputFormControl
                          name="newPasswordConfirmation"
                          placeholder="Password"
                          type="password"
                        />
                        <BootstrapFormikFeedback name="newPasswordConfirmation" />
                      </Form.Group>

                      <Row className={styles.RightAlignedRow}>
                        <Button type="submit">Reset</Button>
                      </Row>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )}
        </>
      )}
    </>
  )
}
