import { CurrentUser } from '../../types'
import { dashboardPath } from '../../components/Router/Routes'
import { menuItemRoutesLookup } from '../menuItems'

/**
 * Get the default path for the user. Defaults the the dashboard if it doesn't exist
 * @param user - The current user if it exists
 */
export const getDefaultPath = (user?: CurrentUser): string =>
  (user && menuItemRoutesLookup[user.defaultMenuId]) || dashboardPath
