import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { loginPath } from '../Router/Routes'
import { useAppContext } from '../../hooks/useAppContext'
import { UNAUTHORIZED_ROUTES } from '../../Routes'

export const ProtectedRoute = ({ children, ...props }: RouteProps): JSX.Element => {
  const { user, initializing } = useAppContext()

  return (
    <Route
      {...props}
      render={({ location }) => {
        if (initializing) {
          return null
        }

        if (!user) {
          return <Redirect to={{ pathname: loginPath, state: { referrer: location.pathname } }} />
        } else if (user.passwordExpired) {
          return (
            <Redirect
              to={{
                pathname: UNAUTHORIZED_ROUTES.passwordExpired,
                state: { referrer: location.pathname },
              }}
            />
          )
        }

        return children
      }}
    />
  )
}
