import React, { FC } from 'react'
import { Form, Button, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import styles from './ChangePasswordForm.module.scss'
import { changePassword } from 'lib/api/user'
import { BootstrapFormikFeedback } from 'components/BootstrapFormikFeedback'
import { FormikFieldAutoTrim } from 'components/FormikFieldAutoTrim'
import { passwordValidator } from 'utils/passwordValidator'
import { useAppContext } from 'hooks/useAppContext'

export type Props = {
  callback: () => void
  formId?: string
  hideSubmit?: boolean
  setError: (text: string) => void
}

export type FormData = {
  newPassword: string
  newPasswordConfirmation: string
  oldPassword: string
}
type OnSubmit = (values: FormData) => void

const ChangePasswordValidationDell = Yup.object().shape({
  oldPassword: Yup.string().required('Please enter your old password'),
  newPassword: Yup.string().required('Please enter a password').test({
    name: 'passwordTest',
    exclusive: true,
    params: undefined,
    message: 'Password does not meet requirements',
    test: passwordValidator,
  }),
  newPasswordConfirmation: Yup.string()
    .required('Please confirm new password')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
})

const ChangePasswordValidationOnePak = Yup.object().shape({
  oldPassword: Yup.string().required('Please enter your old password'),
  newPassword: Yup.string()
    .required('Please enter a password')
    .min(6, 'Password does not meet requirements'),
  newPasswordConfirmation: Yup.string()
    .required('Please confirm new password')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
})

export const ChangePasswordForm: FC<Props> = ({
  formId = 'change-password',
  hideSubmit = false,
  setError,
  callback,
}) => {
  const { user } = useAppContext()

  const onSubmit: OnSubmit = async ({ oldPassword, newPassword }: FormData) => {
    try {
      await changePassword({
        oldPassword,
        newPassword,
      })
      callback()
    } catch (e) {
      setError(e.response.data.error)
    }
  }

  const DellRequirements = () => {
    return (
      <ul className={styles.PasswordCriteria}>
        <li>Passwords expire after 90 days</li>
        <li>Passwords must be at least 8 characters long</li>
        <li>
          Passwords must include 3 out of the following 4 options:
          <ol>
            <li>Uppercase Alpha Character</li>
            <li>Lowercase Alpha Character</li>
            <li>Numeric Character</li>
            <li>&ldquo;Special&rdquo; Character(i.e., @,$,&amp;,*)</li>
          </ol>
        </li>
      </ul>
    )
  }

  const OnePakRequirements = () => {
    return (
      <ul className={styles.PasswordCriteria}>
        <li>Passwords must be at least 6 characters long</li>
      </ul>
    )
  }

  return (
    <div className={styles.Container}>
      <Formik
        initialValues={{ oldPassword: '', newPassword: '', newPasswordConfirmation: '' }}
        onSubmit={onSubmit}
        validationSchema={
          user?.passwordPolicy === 'dell'
            ? ChangePasswordValidationDell
            : ChangePasswordValidationOnePak
        }
      >
        {({ handleSubmit }) => {
          return (
            <Form id={formId} className={styles.Form} onSubmit={handleSubmit}>
              <h2 className={styles.PromptHeading}>Reset Password</h2>
              <p className={styles.PromptText}>
                <strong>Note: your password is set up with the following rules:</strong>
              </p>
              {user?.passwordPolicy === 'dell' ? <DellRequirements /> : <OnePakRequirements />}
              <Form.Group className={styles.Group} controlId="OldPassword">
                <Form.Label className={styles.Label}>Current Password</Form.Label>
                <FormikFieldAutoTrim
                  className={styles.Field}
                  name="oldPassword"
                  placeholder="Current Password"
                  type="password"
                />
                <BootstrapFormikFeedback name="oldPassword" />
              </Form.Group>
              <Form.Group className={styles.Group} controlId="newPassword">
                <Form.Label className={styles.Label}>New Password</Form.Label>
                <FormikFieldAutoTrim
                  className={styles.Field}
                  name="newPassword"
                  placeholder="Password"
                  type="password"
                />
                <BootstrapFormikFeedback name="newPassword" />
              </Form.Group>

              <Form.Group className={styles.Group} controlId="newPasswordConfirmation">
                <Form.Label className={styles.Label}>Re-enter Password</Form.Label>
                <FormikFieldAutoTrim
                  className={styles.Field}
                  name="newPasswordConfirmation"
                  placeholder="Password"
                  type="password"
                />
                <BootstrapFormikFeedback name="newPasswordConfirmation" />
              </Form.Group>

              {!hideSubmit && (
                <Row className={styles.RightAlignedRow}>
                  <Button className={styles.Reset} type="submit">
                    Change Password
                  </Button>
                </Row>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
