import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { UNAUTHORIZED_ROUTES } from 'Routes'
import { ChangePasswordForm } from 'components/ChangePasswordForm'
import { useUnauthorizedErrorMessageContext } from '../../hooks/useUnauthorizedErrorMessageContext'

export const EXPIRED_PASSWORD = 'Your password has expired'

export const ExpiredPasswordForm = (): JSX.Element => {
  const history = useHistory()

  const { setErrorMessage } = useUnauthorizedErrorMessageContext()

  useEffect(() => {
    setErrorMessage(EXPIRED_PASSWORD)
  }, [setErrorMessage])

  return (
    <ChangePasswordForm
      callback={() => history.push(UNAUTHORIZED_ROUTES.resetPasswordSuccess)}
      setError={setErrorMessage}
    />
  )
}
