import * as React from 'react'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import logo from 'components/ReturnCenterLogo/ReturnCenterLogo.svg'
import { UNAUTHORIZED_ROUTES } from 'Routes'

import styles from './UnauthorizedHeader.module.scss'

export type Props = {
  hideMenu?: boolean
}

export const UnauthorizedHeader = ({ hideMenu = false }: Props): JSX.Element => (
  <Navbar bg="white" className={styles.Header} collapseOnSelect expand="sm">
    <Navbar.Brand>
      <img alt="ReturnCenter logo" className={styles.HeaderLogo} src={logo} />
    </Navbar.Brand>

    {!hideMenu && (
      <>
        <Navbar.Toggle aria-controls="nav-menu" />

        <Navbar.Collapse id="nav-menu">
          <Nav className="ml-auto">
            <Link className="nav-link" to={UNAUTHORIZED_ROUTES.login}>
              Login
            </Link>
          </Nav>
        </Navbar.Collapse>
      </>
    )}
  </Navbar>
)
